/* eslint-disable react/prop-types */
import React from 'react';
import './errorpage.scss';

const NotFoundPage = ({ data }) => (
  <div className="container">
    <div className="row">
      <div className="col">
        <div className="pagenotFound">
        <div className="erroemsg">Oops...!</div>
        <div className="homepagelink"><a href="/">GOTO HOME</a></div>
        </div>
      </div>
    </div>
  </div>
);


export default NotFoundPage;
